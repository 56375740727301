import { useEffect, useState } from 'react'
import styles from './index.module.scss'

export function ThemeSwitcher() {
  const [currentTheme, setCurrentTheme] = useState<any>(undefined)
  const isDarkMode = window.matchMedia(`(prefers-color-scheme: dark)`).matches
  const isLightMode = window.matchMedia(`(prefers-color-scheme: light)`).matches
  const isNotSpecified = window.matchMedia(`(prefers-color-scheme: no-preference)`).matches
  const hasNoSupport = !isDarkMode && !isLightMode && !isNotSpecified
  const { body } = document

  useEffect(() => {
    console.log('theme switcher renders')
    const theme = getUserThemePreference()

    if (theme) {
      setCurrentTheme(theme)
      body.className = theme
    }
  }, [])

  function getUserThemePreference() {
    let preference

    // detect if there is an existing theme selection
    const localStorageTheme = localStorage.getItem('currentTheme')

    if (localStorageTheme) {
      preference = localStorageTheme
    } else {
      return undefined
    }

    // detect system theme preference
    if (isDarkMode) {
      preference = localStorageTheme || 'dark'
    } else if (isLightMode) {
      preference = localStorageTheme || 'light'
    } else if (isNotSpecified || hasNoSupport) {
      preference = localStorageTheme || 'light'
    }

    return preference
  }

  function toggleTheme() {
    setCurrentTheme(currentTheme === 'light' ? 'dark' : 'light')
    body.className = currentTheme === 'light' ? 'dark' : 'light'
  }

  useEffect(() => {
    currentTheme && localStorage.setItem('currentTheme', currentTheme)
  }, [currentTheme])

  return (
    <label title="Theme switcher" htmlFor="themeSwitcher">
      <input
        className={styles.ThemeToggle}
        type="checkbox"
        id="themeSwitcher"
        checked={currentTheme === 'light'}
        onChange={toggleTheme}
      />
    </label>
  )
}
