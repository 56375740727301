import { create } from 'zustand'
import { persist } from 'zustand/middleware'
import { languagesMap } from 'src/utils/translation'

export interface LanguageStoreType {
  currentLocale: string
  updateLanguage: (lang: string) => void
}

const getLocaleFromUrlParamether = () => {
  const urlParams = new URL(window.location as any).searchParams
  const langFromParams = urlParams.get('lang')
  const lang = languagesMap[langFromParams as any] ?? 'en-US'

  return lang
}

export const useLanguageStore = create<LanguageStoreType>()(
  persist(
    (set) => ({
      currentLocale: getLocaleFromUrlParamether(),
      updateLanguage: (lang: string) => {
        document.documentElement.setAttribute('lang', lang.split('-')[0])
        set({ currentLocale: lang })
      },
    }),
    {
      name: 'languageStore',
    },
  ),
)
