import { createIntl, createIntlCache, IntlConfig, IntlShape, RawIntlProvider } from 'react-intl'

export type TranslationObject = IntlShape & {
  setDefaultValue?: (key: string, value: unknown) => void
}

const merge = (target, source) => {
  // Iterate through `source` properties and if an `Object` set property to merge of `target` and `source` properties
  for (const key of Object.keys(source)) {
    if (source[key] instanceof Object) Object.assign(source[key], merge(target[key], source[key]))
  }

  // Join `target` and modified `source`
  Object.assign(target || {}, source)

  return target
}

const isObject = (val) => typeof val === 'object' && !Array.isArray(val) && val !== null

export function addFlattenedValues(values: { error?: string }, prefix: string, obj: any) {
  if (!isObject(obj)) {
    values[prefix] = obj
  } else {
    if (Array.isArray(obj)) {
      values[`${prefix}_length`] = obj.length
    }

    Object.keys(obj).forEach((key) => {
      addFlattenedValues(values, `${prefix}_${key}`, obj[key])
    })
  }

  return values
}
type TranslationOptions = Omit<IntlConfig, 'onError'>

export function createTranslationService(options: TranslationOptions, defaults = {}) {
  const DEFAULT_CONFIGURATION = {
    formats: {
      number: {
        currency: {
          style: 'currency',
          currency: 'USD',
          maximumFractionDigits: 2,
        },
        percent: {
          style: 'percent',
          maximumFractionDigits: 2,
        },
      },
    },
    wrapRichTextChunksInFragment: true,
    onError: (err) => {
      const errorMessage = `Translation text was not found. errorMessage: ${err}`

      console.error(errorMessage)
    },
  }

  const cache = createIntlCache()
  const intl: TranslationObject = createIntl(merge(DEFAULT_CONFIGURATION, options), cache)
  const originalFormatMessage = intl.formatMessage
  const defaultValues = defaults

  intl.setDefaultValue = function (key, value) {
    if (!defaultValues[key]) {
      console.error('Only existing keys can be overridden')
    } else if (value) {
      defaultValues[key] = value
    }
  }
  intl.formatMessage = function (descriptor, values) {
    const newValues = values && Object.keys(values).length > 0 ? { ...defaultValues, ...values } : defaultValues

    return originalFormatMessage(descriptor, newValues)
  }

  return intl
}

export { RawIntlProvider as TranslationProvider }
