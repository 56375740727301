import { useState } from 'react'
import { FormattedText } from 'src/components/FormattedText'
import { Button } from 'src/core/ds/button'
import { Container } from 'src/core/ds/container'
import { Input } from 'src/core/ds/input'
import { useEmailService } from 'src/services/email/query'
import { ReactComponent as CointrapperLogo } from '../../assets/images/cointrapper-logo.svg'
import styles from './index.module.scss'

export function Footer() {
  const [email, setEmail] = useState('')

  const handleEmailChange = (e) => {
    setEmail(e.target.value)
  }

  const emailService = useEmailService()

  const { isLoading, isSuccess, mutate } = emailService.useSubscribeEmail()

  const isFormDisabled = isLoading || isSuccess

  const onSubmit = async (e) => {
    e.preventDefault()

    if (!email.length) {
      return
    }

    await mutate({ email, token: window.cfToken })
  }

  return (
    <footer id="footer" className={styles.footer}>
      <Container>
        <div className={styles.footerTop}>
          <div className={styles.nav}>
            <ul>
              <li>
                <a href="#advantages">
                  <FormattedText label="footer.menu.advantages" />
                </a>
              </li>
              <li>
                <a href="#calculator">
                  <FormattedText label="footer.menu.calculator" />
                </a>
              </li>
              <li>
                Contact:
                <br />
                <a href="mailto:info@cointrapper.com">info@cointrapper.com</a>
              </li>
            </ul>

            <ul>
              <li>
                <a href="#faq">
                  <FormattedText label="footer.menu.faq" />
                </a>
              </li>
              <li>
                <a href="#footer">
                  <FormattedText label="footer.menu.subscribe" />
                </a>
              </li>
            </ul>
          </div>

          <div className={styles.form}>
            <p>
              <FormattedText label="footer.updates" />
            </p>
            <form onSubmit={onSubmit}>
              <Input
                isDisabled={isFormDisabled}
                onChange={handleEmailChange}
                value={email}
                type="email"
                placeholder="youremail@email.com"
              />
              <Button isLoading={isLoading} disabled={isFormDisabled} type="submit" label="footer.sub" />
            </form>
          </div>
        </div>

        <div className={styles.footerBottom}>
          <div>
            <a title="Cointrapper" href="/">
              <CointrapperLogo />
            </a>
            <p>All rights reserved. 2023.</p>
          </div>
        </div>
      </Container>
    </footer>
  )
}
