export enum ButtonVariants {
  tertiaryNaked = 'tertiaryNaked',
  secondary = 'secondary',
  primary = 'primary',
  error = 'error',
  success = 'success',
  tertiary = 'tertiary',
  unstyled = 'unstyled',
}

export enum ButtonSizes {
  xs = 'xs',
  s = 's',
  md = 'md',
  l = 'l',
  xl = 'xl',
}

export type ButtonType = JSX.IntrinsicElements['button']['type']
