import { FormattedMessage } from 'react-intl'
import { defaultFormatters } from './utils'

export function FormattedText({ label, values = {} }) {
  if (!label) {
    return null
  }

  return <FormattedMessage id={label} values={{ ...values, ...defaultFormatters }} />
}
