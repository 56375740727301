import React from 'react'
import { Container } from 'src/core/ds/container'
import styles from './index.module.scss'

export class ErrorBoundary extends React.PureComponent<any, any> {
  constructor(props) {
    super(props)
    this.state = {
      hasError: false,
    }
  }

  componentDidCatch() {
    this.setState({ hasError: true })
  }

  render() {
    const { hasError } = this.state
    const { children } = this.props

    if (hasError) {
      return (
        <Container>
          <div className={styles.error}>
            <div>
              <h1>Something went wrong!</h1>
              <p>We will handle this issue soon.</p>
              <a href="/">Go back home</a>
            </div>
          </div>
        </Container>
      )
    }

    return children
  }
}
