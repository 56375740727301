import { lazy, Suspense, useEffect, useState } from 'react'
import { FormattedMessage } from 'react-intl'
import Turnstile from 'react-turnstile'
import { FormattedText } from 'src/components/FormattedText'
import { Button } from 'src/core/ds/button'
import { Container } from 'src/core/ds/container'
import { Input } from 'src/core/ds/input'
import { InputSizes } from 'src/core/ds/input/types'
import { useEmailService } from 'src/services/email/query'
import IphoneImage from '../../assets/images/iphone.webp'
import styles from './index.module.scss'
import { calculateProfit } from './utils'

declare global {
  interface Window {
    cfToken: string
    plausible: any
  }
}

const LazyChart = lazy(() => import('src/components/ROIChart').then((module) => ({ default: module.ROIChart })))

export function LandingPage() {
  const [email, setEmail] = useState('')
  const [amount, setAmount] = useState('500')
  const [selectedDays, setSelectedDays] = useState(60)
  const [calculatedAmount, setCalculatedAmount] = useState(0)

  const emailService = useEmailService()

  const { isLoading, isSuccess, mutate } = emailService.useSubscribeEmail()

  const isFormDisabled = isLoading || isSuccess

  const onSubmit = async (e) => {
    e.preventDefault()

    if (!email.length) {
      return
    }

    await mutate({ email, token: window.cfToken })
  }

  useEffect(() => {
    // calculate amount on first render
    const profit = calculateProfit(Number(amount), selectedDays)
    setCalculatedAmount(profit)
  }, [])

  useEffect(() => {
    // calculate amount on first render
    const profit = calculateProfit(Number(amount), selectedDays)
    setCalculatedAmount(profit)
  }, [amount, selectedDays])

  const handleEmailChange = (e) => {
    setEmail(e.target.value)
  }

  const handleAmountChange = (e) => {
    setAmount(e.target.value.replace(/\D/g, ''))
  }

  const buttonsList = [30, 60, 90, 120]

  return (
    <Container>
      <div className={styles.hero}>
        <div className={styles.launch}>
          <span>
            <FormattedMessage id="landing.hero.launch" />
          </span>
        </div>
        <h1>
          <FormattedText label="landing.hero.title" />
        </h1>
        <p>
          <FormattedText label="landing.hero.subtitle" />
        </p>

        <div className={styles.form}>
          <form onSubmit={onSubmit}>
            <Input
              isDisabled={isFormDisabled}
              onChange={handleEmailChange}
              value={email}
              type="email"
              placeholder="youremail@email.com"
            />
            <Button isLoading={isLoading} disabled={isFormDisabled} type="submit" label="landing.join" />
            <div className="turnstile">
              <Turnstile
                theme="light"
                sitekey="0x4AAAAAAACi_-3UUswOcDnS"
                onVerify={(token) => {
                  window.cfToken = token
                }}
              />
            </div>
          </form>
          <p>
            <FormattedText label="landing.fees" />
          </p>
        </div>
      </div>

      <section id="advantages" className={styles.advantages}>
        <h2>
          <FormattedText label="landing.advantages.title" />
        </h2>
        <p>
          <FormattedText label="landing.advantages.subtitle" />
        </p>

        <div className={styles.advantagesList}>
          <div className={styles.advantagesLeft}>
            <div className={styles.advantageItem}>
              <span>
                <FormattedText label="landing.advantages.list.l1.title" />
              </span>
              <p>
                <FormattedText label="landing.advantages.list.l1.subtitle" />
              </p>
            </div>

            <div className={styles.advantageItem}>
              <span>
                <FormattedText label="landing.advantages.list.l2.title" />
              </span>
              <p>
                <FormattedText label="landing.advantages.list.l2.subtitle" />
              </p>
            </div>

            <div className={styles.advantageItem}>
              <span>
                <FormattedText label="landing.advantages.list.l3.title" />
              </span>
              <p>
                <FormattedText label="landing.advantages.list.l3.subtitle" />
              </p>
            </div>
          </div>

          <div className={styles.advantagesCenter}>
            <img loading="lazy" width="301" height="602" src={IphoneImage} alt="Cointrapper interface" />
          </div>

          <div className={styles.advantagesRight}>
            <div className={styles.advantageItem}>
              <span>
                <FormattedText label="landing.advantages.list.l4.title" />
              </span>
              <p>
                <FormattedText label="landing.advantages.list.l4.subtitle" />
              </p>
            </div>

            <div className={styles.advantageItem}>
              <span>
                <FormattedText label="landing.advantages.list.l5.title" />
              </span>
              <p>
                <FormattedText label="landing.advantages.list.l5.subtitle" />
              </p>
            </div>

            <div className={styles.advantageItem}>
              <span>
                <FormattedText label="landing.advantages.list.l6.title" />
              </span>
              <p>
                <FormattedText label="landing.advantages.list.l6.subtitle" />
              </p>
            </div>
          </div>
        </div>
      </section>

      <section id="roi" className={styles.chart}>
        <div className={styles.chartContent}>
          <h2>
            <FormattedText label="landing.roi.title" />
          </h2>
          <p>
            <FormattedText label="landing.roi.subtitle" />
          </p>
        </div>

        <div className={styles.chartContainer}>
          <Suspense fallback={<div />}>
            <LazyChart />
          </Suspense>
        </div>
      </section>

      <section id="calculator" className={styles.calculator}>
        <div className={styles.calculatorContent}>
          <h2>
            <FormattedText label="landing.calculator.title" />
          </h2>
          <p>
            <FormattedText label="landing.calculator.subtitle" />
          </p>
        </div>

        <div className={styles.calculatorContainer}>
          <div className={styles.calculatorContainerText}>
            <p>
              <FormattedText label="landing.calculator.description" />
            </p>

            <span>
              <FormattedText label="landing.calculator.note" />
            </span>
          </div>
          <div className={styles.calculatorContainerForm}>
            <Input
              type="number"
              prefix="$"
              onChange={handleAmountChange}
              size={InputSizes.l}
              value={amount}
              placeholder="50"
            />
            <div className={styles.duration}>
              <span>
                <FormattedText label="landing.calculator.duration" />
              </span>
              <div className={styles.durationActions}>
                {buttonsList.map((days) => (
                  <button
                    onClick={() => setSelectedDays(days)}
                    type="button"
                    className={selectedDays === days ? styles.selectedDay : ''}
                    key={days}
                  >
                    {days}
                  </button>
                ))}
              </div>
              <span>
                <FormattedText label="landing.calculator.days" />
              </span>
            </div>

            <div className={styles.calculatorTotal}>
              <div>
                <span>
                  <FormattedText label="landing.calculator.expected" />
                </span>
              </div>
              <div>
                <p>{`$ ${calculatedAmount.toLocaleString()}`}</p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section id="faq" className={styles.faq}>
        <h2>
          <FormattedText label="landing.faq.title" />
        </h2>
        <p>
          <FormattedText label="landing.faq.subtitle" />
        </p>

        <div className={styles.faqList}>
          <div className={styles.faqItem}>
            <span>
              <FormattedText label="landing.faq.list.l1.title" />
            </span>
            <p>
              <FormattedText label="landing.faq.list.l1.subtitle" />
            </p>
          </div>

          <div className={styles.faqItem}>
            <span>
              <FormattedText label="landing.faq.list.l2.title" />
            </span>
            <p>
              <FormattedText label="landing.faq.list.l2.subtitle" />
            </p>
          </div>

          <div className={styles.faqItem}>
            <span>
              <FormattedText label="landing.faq.list.l3.title" />
            </span>
            <p>
              <FormattedText label="landing.faq.list.l3.subtitle" />
            </p>
          </div>

          <div className={styles.faqItem}>
            <span>
              <FormattedText label="landing.faq.list.l4.title" />
            </span>
            <p>
              <FormattedText label="landing.faq.list.l4.subtitle" />
            </p>
          </div>

          <div className={styles.faqItem}>
            <span>
              <FormattedText label="landing.faq.list.l5.title" />
            </span>
            <p>
              <FormattedText label="landing.faq.list.l5.subtitle" />
            </p>
          </div>

          <div className={styles.faqItem}>
            <span>
              <FormattedText label="landing.faq.list.l6.title" />
            </span>
            <p>
              <FormattedText label="landing.faq.list.l6.subtitle" />
            </p>
          </div>
        </div>
      </section>
    </Container>
  )
}
