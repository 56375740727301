import { config } from 'src/config/config'

export const calculateProfit = (amount: number, days: number) => {
  // Calculate the profit based on the formula: profit = amount * percent * days / 365
  const PERCENT = config.fixedPercent
  const profit = (amount * PERCENT * days) / 365
  // Round the profit to 2 decimal places
  const roundedProfit = Math.round(profit * 100) / 100
  // Return the rounded profit

  return Number((+roundedProfit + amount).toFixed(2))
}
