import './reset.css'
import './fonts.css'
import './theme.css'

import { Header } from './Header'

export function AppLayout({ children }) {
  return (
    <>
      <Header />
      <main>{children}</main>
    </>
  )
}
