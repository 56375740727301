import { Button } from 'src/core/ds/button'
import { ButtonVariants } from 'src/core/ds/button/types'
import { Dropdown } from 'src/core/ds/dropdown'
import { Icon, IconNames, IconSize } from 'src/core/ds/icon'
import { useLocalisationStore } from 'src/services/store/TranslationStore'
import { localisationMap } from 'src/utils/translation'
import styles from './index.module.scss'

export function LanguageSwitcher() {
  const { updateCurrentLocal, currentLocale, localisations } = useLocalisationStore()

  return (
    <div className={styles.container}>
      <Dropdown
        lefted
        visibleContent={
          // eslint-disable-next-line
          <div className={styles.containerBox}>
            <Icon size={IconSize.xs} name={IconNames.globus} />
            <Button
              iconColor="var(--color-grey)"
              variant={ButtonVariants.unstyled}
              type="button"
              label={localisationMap[currentLocale]}
              icon={IconNames.shevronDown}
            />
          </div>
        }
        hiddenContent={
          // eslint-disable-next-line
          <ul className={styles.hidden}>
            {localisations?.map((lang) => (
              <li key={lang}>
                <Button
                  variant={ButtonVariants.unstyled}
                  onClick={() => updateCurrentLocal(lang)}
                  type="button"
                  label={localisationMap[lang]}
                />
              </li>
            ))}
          </ul>
        }
      />
    </div>
  )
}
