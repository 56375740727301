import { ReactLocation, Route } from '@tanstack/react-location'
import { MetaTitle } from './components/MetaTitle'
import { DashboardLayout } from './layouts/DashboardLayout'
import { DashboardPage } from './pages/Dashboard'
import { LandingPage } from './pages/Landing'

export const location = new ReactLocation()

enum ROUTES {
  landing = '/',
  login = 'login',
  signup = 'signup',
  dashboard = 'dashboard',
}

export const routes: Route[] = [
  {
    path: ROUTES.landing,
    element: <LandingPage />,
  },
  {
    path: ROUTES.dashboard,
    element: (
      <DashboardLayout>
        <MetaTitle title="Dashboard" />
        <DashboardPage />
      </DashboardLayout>
    ),
  },
  {
    path: ROUTES.login,
    element: (
      <div>
        <MetaTitle title="Login" />
        <h1>Login</h1>
      </div>
    ),
  },
  {
    path: ROUTES.signup,
    element: (
      <div>
        <MetaTitle title="Signup" />
        <h1>signup</h1>
      </div>
    ),
  },
]
