import { useState } from 'react'
import { FormattedMessage } from 'react-intl'
import { FormattedText } from 'src/components/FormattedText'
import { LanguageSwitcher } from 'src/components/LanguageSwitcher'
import { ThemeSwitcher } from 'src/components/ThemeSwitcher'
import { Button } from 'src/core/ds/button'
import { ButtonSizes, ButtonVariants } from 'src/core/ds/button/types'
import { Container } from 'src/core/ds/container'
import { IconNames } from 'src/core/ds/icon'
import { useMedia } from 'src/hooks/useMedia'
import { pushNotification } from 'src/services/notifications/notificationService'
import { ReactComponent as CointrapperLogo } from '../../assets/images/cointrapper-logo.svg'
import styles from './index.module.scss'

export function Header() {
  const authTitle = <FormattedText label="errors.modalAuth.title" />
  const authSubtitle = <FormattedText label="errors.modalAuth.subtitle" />
  const [isDrawerOpen, setIsDrawerOpen] = useState(false)

  const handleLoginButtons = () => {
    pushNotification({
      type: 'default',
      title: authTitle,
      subtitle: authSubtitle,
    })
  }

  const width = useMedia()

  if (width <= 820) {
    return (
      <header className={styles.header}>
        {isDrawerOpen && (
          <div className={styles.drawer}>
            <Container>
              <div className={styles.drawerAction}>
                <Button
                  icon={IconNames.close}
                  variant={ButtonVariants.unstyled}
                  size={ButtonSizes.xs}
                  type="button"
                  iconColor="var(--color-text)"
                  label=""
                  onClick={() => setIsDrawerOpen(false)}
                />
              </div>

              <nav className={styles.drawerNav}>
                <ul>
                  <li>
                    <a onClick={() => setIsDrawerOpen(false)} href="#advantages">
                      <FormattedMessage id="header.menu.advantages" />
                    </a>
                  </li>
                  <li>
                    <a onClick={() => setIsDrawerOpen(false)} href="#calculator">
                      <FormattedMessage id="header.menu.calculator" />
                    </a>
                  </li>
                  <li>
                    <a onClick={() => setIsDrawerOpen(false)} href="#faq">
                      <FormattedMessage id="header.menu.faq" />
                    </a>
                  </li>
                  <li>
                    <a onClick={() => setIsDrawerOpen(false)} href="#footer">
                      <FormattedMessage id="header.menu.subscribe" />
                    </a>
                  </li>
                </ul>
              </nav>

              <div className={styles.drawerLanguage}>
                <LanguageSwitcher />
                <ThemeSwitcher />
              </div>
            </Container>
          </div>
        )}
        <Container>
          <div className={styles.wrapper}>
            <a title="Cointrapper" className={styles.logo} href="/">
              <CointrapperLogo />
            </a>

            <div className={styles.mobileAction}>
              <div className={styles.mobileActionButtons}>
                <Button
                  onClick={handleLoginButtons}
                  size={ButtonSizes.xs}
                  variant={ButtonVariants.secondary}
                  type="button"
                  label="header.auth.signin"
                />
                <Button onClick={handleLoginButtons} size={ButtonSizes.xs} type="button" label="header.auth.signup" />
              </div>
              <div className={styles.hamburger}>
                <Button
                  icon={IconNames.hamburger}
                  variant={ButtonVariants.unstyled}
                  size={ButtonSizes.xs}
                  type="button"
                  title="Open menu"
                  onClick={() => setIsDrawerOpen(true)}
                  iconColor="var(--color-text)"
                  label=""
                />
              </div>
            </div>
          </div>
        </Container>
      </header>
    )
  }

  return (
    <header className={styles.header}>
      <Container>
        <div className={styles.wrapper}>
          <nav>
            <a title="Cointrapper" className={styles.logo} href="/">
              <CointrapperLogo />
            </a>

            <ul className={styles.nav}>
              <li>
                <a href="#advantages">
                  <FormattedMessage id="header.menu.advantages" />
                </a>
              </li>
              <li>
                <a href="#calculator">
                  <FormattedMessage id="header.menu.calculator" />
                </a>
              </li>
              <li>
                <a href="#faq">
                  <FormattedMessage id="header.menu.faq" />
                </a>
              </li>
              <li>
                <a href="#footer">
                  <FormattedMessage id="header.menu.subscribe" />
                </a>
              </li>
            </ul>
          </nav>

          <div className={styles.auth}>
            <LanguageSwitcher />
            <ThemeSwitcher />
            <Button
              onClick={handleLoginButtons}
              size={ButtonSizes.xs}
              variant={ButtonVariants.secondary}
              type="button"
              label="header.auth.signin"
            />
            <Button onClick={handleLoginButtons} size={ButtonSizes.xs} type="button" label="header.auth.signup" />
          </div>
        </div>
      </Container>
    </header>
  )
}
