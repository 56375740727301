import { createContext, useContext } from 'react'
import { locale } from 'src/locale/en-US'

const LocalisationStoreContext = createContext<any>({})
export const useLocalisationStore = () => useContext(LocalisationStoreContext)
const localisations = Object.keys(locale)

export function LocalisationProvider({ children, currentLocale, updateCurrentLocal }: any) {
  return (
    // eslint-disable-next-line
    <LocalisationStoreContext.Provider value={{ localisations, currentLocale, updateCurrentLocal }}>
      {children}
    </LocalisationStoreContext.Provider>
  )
}
