import { Link } from '@tanstack/react-location'
import { Icon, IconNames, IconSize } from 'src/core/ds/icon'
import styles from './index.module.scss'

const topNavigation = [
  {
    title: 'Dashboard',
    icon: <Icon size={IconSize.xs} color="var(--color-secondary)" name={IconNames.dashboard} />,
    href: '/dashboard',
  },
  {
    title: 'Deposit',
    icon: <Icon size={IconSize.xs} color="var(--color-secondary)" name={IconNames.wallet} />,
    href: '/dashboard/deposit',
  },
  {
    title: 'Withdraw',
    icon: <Icon size={IconSize.xs} color="var(--color-secondary)" name={IconNames.bankOut} />,
    href: '/dashboard/withdraw',
  },
  {
    title: 'History',
    icon: <Icon size={IconSize.xs} color="var(--color-secondary)" name={IconNames.bills} />,
    href: '/dashboard/history',
  },
]

export function DashboardNavbar() {
  return (
    <div className={styles.navbar}>
      <ul>
        {topNavigation.map((navItem: any) => (
          <li>
            <Link to={navItem.href}>
              {navItem.icon}
              {navItem.title}
            </Link>
          </li>
        ))}
      </ul>

      <div className={styles.subnavigation}>
        <ul>
          <li>
            <Link to="/">
              <Icon size={IconSize.xs} color="var(--color-secondary)" name={IconNames.creditCard} />
              Billing
            </Link>
          </li>
          <li>
            <Link to="/">
              <Icon size={IconSize.xs} color="var(--color-secondary)" name={IconNames.helpCircle} />
              Help & support
            </Link>
          </li>
        </ul>
      </div>
    </div>
  )
}
