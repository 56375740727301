import { Icon, IconNames, IconSize } from '../icon'
import styles from './index.module.scss'
import { InputSizes } from './types'

type InputProps = {
  value: string
  placeholder?: string
  isDisabled?: boolean
  type?: string
  size?: InputSizes
  isFullWidth?: boolean
  onChange?: any
  onClick?: any
  onFocus?: any
  onBlur?: any
  prefix?: string
  icon?: IconNames
  stablePrefix?: string
  label?: string
  id?: string
  action?: any
  subValue?: string
  error?: string
}

export function Input({
  type = 'text',
  size = InputSizes.m,
  value,
  prefix,
  onFocus,
  onClick,
  isFullWidth = false,
  placeholder,
  isDisabled = false,
  icon,
  label,
  onChange,
  onBlur,
  stablePrefix,
  id = 'input',
  action,
  subValue,
  error,
}: InputProps) {
  return (
    <>
      {label && (
        <label className={styles.label} htmlFor={id}>
          {label}
        </label>
      )}
      <div className={styles.inputContainer}>
        {stablePrefix && <div className={styles.stablePrefix}>{stablePrefix}</div>}
        {prefix && <span className={`${styles.prefixContainer} ${styles[`prefix-size-${size}`]}`}>{prefix}</span>}
        {(action || subValue) && (
          <div className={styles.subContainer}>
            <span>{subValue}</span>
            {action}
          </div>
        )}
        {icon && (
          <span className={styles.iconContainer}>
            <Icon name={icon} color="var(--color-secondary)" />
          </span>
        )}
        <input
          className={`${styles.input} ${styles[size]} ${isFullWidth ? styles.full : ''} ${
            prefix ? styles.prefix : ''
          } ${error ? styles.error : ''} ${icon ? styles.prefixIcon : ''}  ${
            stablePrefix ? styles.stablePrefixInput : ''
          }`}
          type={type}
          id={id}
          onClick={onClick}
          onBlur={onBlur}
          onFocus={onFocus}
          onChange={onChange}
          value={value}
          placeholder={placeholder}
          disabled={isDisabled}
        />
      </div>
      {error && (
        <div className={styles.errorField}>
          <Icon size={IconSize.xs} name={IconNames.alertFill} color="var(--color-red)" />
          <span>{error}</span>
        </div>
      )}
    </>
  )
}
