import { Outlet, Router } from '@tanstack/react-location'
import { Toaster } from 'react-hot-toast'
import { QueryClientProvider } from 'react-query'
import { ErrorBoundary } from './components/ErrorBoundary'
import { IconsSpriteContainer } from './core/ds/iconsSprite'
import { AppLayout } from './layouts/AppLayout'
import { Footer } from './layouts/Footer'
import { locale } from './locale/en-US'
import { queryClient } from './queries/reactQueryClient'
import { location, routes } from './Routes'
import { useLanguageStore } from './services/store/LanguageStore'
import { LocalisationProvider } from './services/store/TranslationStore'
import { createTranslationService, TranslationProvider } from './services/translation/translation-service'
import { flattenMessages } from './utils/translation'

export function App() {
  const currentLocale = useLanguageStore((state) => state.currentLocale)
  const updateLanguage = useLanguageStore((state) => state.updateLanguage)
  document.documentElement.setAttribute('lang', currentLocale.split('-')[0])

  const intl = createTranslationService({
    locale: currentLocale,
    defaultLocale: currentLocale,
    formats: {},
    defaultFormats: {},
    messages: flattenMessages(locale[currentLocale]),
  })

  return (
    <QueryClientProvider client={queryClient}>
      {/* eslint-disable-next-line */}
      <LocalisationProvider currentLocale={currentLocale} updateCurrentLocal={updateLanguage}>
        <TranslationProvider value={intl}>
          <IconsSpriteContainer />

          <ErrorBoundary>
            <AppLayout>
              <Router routes={routes} location={location}>
                <Outlet />
              </Router>
              <Footer />
            </AppLayout>
          </ErrorBoundary>

          <Toaster position="top-right" />
        </TranslationProvider>
      </LocalisationProvider>
    </QueryClientProvider>
  )
}
