export const locale = {
  'en-US': {
    header: {
      menu: {
        advantages: 'Advantages',
        calculator: 'Calculator',
        faq: 'FAQ',
        subscribe: 'Subscribe',
      },
      auth: {
        signin: 'Sign in',
        signup: 'Sign up',
      },
    },
    footer: {
      menu: {
        advantages: 'Advantages',
        calculator: 'Calculator',
        faq: 'FAQ',
        subscribe: 'Subscribe',
      },
      updates: "Don't miss updates:",
      sub: 'Subscribe',
    },
    landing: {
      faq: {
        title: 'Frequently asked questions',
        subtitle: 'If you can’t find what you’re looking for, email our support team and someone will get back to you.',
        list: {
          l1: {
            title: 'Do you have affiliate program and how does it work?',
            subtitle:
              'Our referral system allows you to invite friends to join our platform and earn passive income from their earnings. When a friend signs up using your referral link and starts earning profits, you will receive a percentage of their earnings as a commission. More info soon.',
          },
          l2: {
            title: 'Are there any trading fees?',
            subtitle:
              'We do not charge any fees for trading on our platform. However, there is a small fee, similar to that of exchanges, for each trade performed by the algorithm.',
          },
          l3: {
            title: 'Are there any fees for deposits?',
            subtitle:
              'No, there are no extra charges for deposits. You can deposit funds into your account with no additional fees.',
          },
          l4: {
            title: 'Are there any fees for withdrawals?',
            subtitle:
              'We charge a low processing fee for each withdrawal that depends on the network load. However, there are no time limitations or finance locks on withdrawals, allowing you to withdraw your funds at any time.',
          },
          l5: {
            title: 'Can I reinvest my earnings?',
            subtitle:
              'Yes, you can reinvest your earnings to embrace compound percentage or keep earning it with your base investment. Our platform allows you to customize your investment strategy to best suit your goals and preferences.',
          },
          l6: {
            title: 'Do I need to configure anything to use the platform?',
            subtitle:
              'No, you do not need to configure anything. Our custom-made algorithm is designed to manage the fund on its own, allowing you to simply participate in the AI-managed fund and enjoy the benefits of our trading expertise.',
          },
        },
      },
      calculator: {
        title: 'Profitability Calculator',
        subtitle: 'No magic, just pure tech powered by AI and math.',
        description:
          'Use our profitability calculator to determine how much you can earn by investing a certain amount in our AI-powered crypto trading algorithm. Simply enter the amount you wish to invest and select the period of investment to calculate your potential earnings.',
        note: '*Please note that the results of the profitability calculator are based on real trading data from the past year. While we cannot guarantee future results, we believe that our algorithm has a proven track record of success and can help you maximize your profits.',
        duration: 'Duration:',
        days: 'days',
        expected: 'Expected returns',
        calculate: 'Calculate',
      },
      roi: {
        title: 'Profitability chart (ROI)',
        subtitle: 'See your earnings soar: discover the potential of your investments on this chart.',
      },
      advantages: {
        title: 'Safe and secure trading on autopilot',
        subtitle:
          'Cointrapper was designed for investors who want to use advanced automation and high-level security to manage their investments securely and efficiently.',
        list: {
          l1: {
            title: 'Effortless Trading Without Human Involvement',
            subtitle:
              'Our AI-powered crypto trading algorithm automates the trading process, eliminating the need for human involvement and allowing you to sit back and watch your investments grow.',
          },
          l2: {
            title: 'Stay Ahead with AI Signal Monitoring',
            subtitle:
              'Our AI-powered algorithm monitors signals from around the world, providing you with up-to-date information on market trends and allowing you to stay ahead of the competition.',
          },
          l3: {
            title: 'Maximize Profits with Diversification',
            subtitle:
              'Our algorithm trades a variety of different coins, allowing you to diversify your portfolio and minimize risk, while maximizing profits.',
          },
          l4: {
            title: 'Proven Results from Live Trading Data',
            subtitle:
              'All of our algorithms are tested on real live data, ensuring that they are effective and reliable in real-world scenarios.',
          },
          l5: {
            title: 'Easy to Use, Minimal Setup Required',
            subtitle:
              'Our algorithm requires minimal participation from the investor. The settings are easy to configure and require little technical knowledge, making it accessible to investors of all levels.',
          },
          l6: {
            title: 'Multi-Level Traps for Optimized Trading',
            subtitle:
              'Our unique Cointrap algorithm sets up traps of multiple levels to optimize trading strategies, taking into account real-time market data to maximize profits.',
          },
        },
      },
      fees: 'Join now to get access to unique benefits and advantages',
      join: 'Join waitlist',
      hero: {
        launch: 'Launching soon! 🚀',
        title: 'Automated <span> AI-powered</span>{br}crypto trading algorithm',
        subtitle: 'Revolutionize trading with advanced AI: {br} maximize efficiency and generate profits in real-time.',
      },
    },
    errors: {
      modalAuth: {
        title: `Sorry, you can't authorize at the moment.`,
        subtitle: 'Project is in development mode.{br} Please, subscribe to receive updates on launch.',
      },
    },
  },
  'ru-RU': {
    header: {
      auth: {
        signin: 'Войти',
        signup: 'Регистрация',
      },
      menu: {
        advantages: 'Преимущества',
        calculator: 'Калькулятор',
        faq: 'FAQ',
        subscribe: 'Рассылка',
      },
    },
    footer: {
      menu: {
        advantages: 'Преимущества',
        calculator: 'Калькулятор',
        faq: 'FAQ',
        subscribe: 'Рассылка',
      },
      updates: 'Не пропустите обновления:',
      sub: 'Подписаться',
    },
    landing: {
      faq: {
        title: 'Часто задаваемые вопросы',
        subtitle: 'Если вы не можете найти то, что ищете, напишите в нашу службу поддержки, и вам ответят.',
        list: {
          l1: {
            title: 'Есть ли у вас партнерская программа и как она работает?',
            subtitle:
              'Наша реферальная система позволяет вам приглашать друзей присоединиться к нашей платформе и получать пассивный доход от их заработка. Когда друг зарегистрируется по вашей реферальной ссылке и начнет получать прибыль, вы будете получать процент от его заработка в качестве комиссионных. Дополнительная информация скоро.',
          },
          l2: {
            title: 'Существуют ли какие-либо торговые комиссии?',
            subtitle:
              'Мы не взимаем никаких комиссий за торговлю на нашей платформе. Однако за каждую сделку, совершенную алгоритмом, взимается небольшая плата, аналогичная биржевой.',
          },
          l3: {
            title: 'Взимаются ли какие-либо комиссии за депозиты?',
            subtitle:
              'Нет, дополнительные сборы за депозиты не взимаются. Вы можете вносить средства на свой счет без дополнительных комиссий.',
          },
          l4: {
            title: 'Существуют ли какие-либо комиссии за снятие средств?',
            subtitle:
              'Мы взимаем небольшую плату за обработку каждого снятия средств, которая зависит от загруженности сети. Однако на снятие средств не накладываются временные ограничения или финансовые блокировки, что позволяет вам снимать средства в любое время.',
          },
          l5: {
            title: 'Могу ли я реинвестировать свои доходы?',
            subtitle:
              'Да, вы можете реинвестировать свои доходы, чтобы увеличить сложный процент или продолжать зарабатывать его с базовыми инвестициями. Наша платформа позволяет вам настроить свою инвестиционную стратегию в соответствии с вашими целями и предпочтениями.',
          },
          l6: {
            title: 'Нужно ли мне что-то настраивать для использования платформы?',
            subtitle:
              'Нет, вам не нужно ничего настраивать. Наш индивидуальный алгоритм предназначен для самостоятельного управления, что позволяет вам просто участвовать в фонде, управляемом ИИ, и пользоваться преимуществами нашей экспертизы.',
          },
        },
      },
      calculator: {
        title: 'Калькулятор прибыли',
        subtitle: 'Никакой магии, только чистые технологии, основанные на искусственном интеллекте и математике.',
        description:
          'Используйте наш калькулятор доходности, чтобы определить, сколько вы можете заработать, инвестировав определенную сумму в наш алгоритм торговли криптовалютами на основе искусственного интеллекта. Просто введите сумму, которую вы хотите инвестировать, и выберите период инвестирования, чтобы рассчитать свой потенциальный доход.',
        note: '*Обратите внимание, что результаты калькулятора прибыльности основаны на реальных торговых данных за последний год. Хотя мы не можем гарантировать будущие результаты, мы считаем, что наш алгоритм имеет проверенную историю успеха и может помочь вам максимизировать вашу прибыль.',
        duration: 'Срок:',
        days: 'дней',
        expected: 'Ожидаемые результаты',
        calculate: 'Рассчитать',
      },
      roi: {
        title: 'График прибыли (ROI)',
        subtitle: 'Увидьте, как растут ваши доходы: раскройте потенциал ваших инвестиций на этом графике.',
      },
      advantages: {
        title: 'Безопасный трейдинг на автопилоте',
        subtitle:
          'Cointrapper был разработан для инвесторов, которые хотят использовать передовую автоматизацию и высокий уровень безопасности для эффективного управления своими инвестициями.',
        list: {
          l1: {
            title: 'Эффективная торговля без участия человека',
            subtitle:
              'Наш алгоритм торговли криптовалютами, основанный на ИИ, автоматизирует процесс торговли, устраняя необходимость участия человека и позволяя вам спокойно наблюдать за ростом ваших инвестиций.',
          },
          l2: {
            title: 'Будьте впереди с помощью мониторинга сигналов ИИ',
            subtitle:
              'Наш алгоритм, основанный на искусственном интеллекте, отслеживает сигналы со всего мира, предоставляя вам актуальную информацию о тенденциях рынка и позволяя опережать конкурентов.',
          },
          l3: {
            title: 'Максимизация прибыли с помощью диверсификации',
            subtitle:
              'Наш алгоритм торгует множеством различных монет, позволяя вам диверсифицировать свой портфель и минимизировать риск, одновременно максимизируя прибыль.',
          },
          l4: {
            title: 'Доказанные результаты на основе реальных торговых данных',
            subtitle:
              'Все наши алгоритмы тестируются на реальных данных, что гарантирует их эффективность и надежность в реальных сценариях.',
          },
          l5: {
            title: 'Простота в использовании, требуется минимальная настройка',
            subtitle:
              'Наш алгоритм требует минимального участия инвестора. Параметры легко настраиваются и не требуют особых технических знаний, что делает его доступным для инвесторов любого уровня.',
          },
          l6: {
            title: 'Многоуровневые "ловушки" для оптимизированной торговли',
            subtitle:
              'Наш уникальный алгоритм Cointrap устанавливает "ловушки" нескольких уровней для оптимизации торговых стратегий, учитывая рыночные данные в реальном времени для максимизации прибыли.',
          },
        },
      },
      fees: 'Сниженная комиссия на старте.',
      join: 'Вступить',
      hero: {
        launch: 'Скоро запуск! 🚀',
        title: 'Автоматический <span> AI-powered</span>{br}торговый алгоритм',
        subtitle:
          'Улучшите свою торговлю с помощью ИИ: раскройте силу {br} машинного обучения для более разумных инвестиций.',
      },
    },
    errors: {
      modalAuth: {
        title: `Извините, вы не можете авторизоваться сейчас.`,
        subtitle: 'Проект находится в разработке.{br} Пожалуйста, подпишитесь на рассылку, чтобы не пропустить запуск.',
      },
    },
  },
  'de-DE': {
    header: {
      menu: {
        advantages: 'Vorteile',
        calculator: 'Rechner',
        faq: 'FAQ',
        subscribe: 'Abonnieren',
      },
      auth: {
        signin: 'Anmelden',
        signup: 'Registrieren',
      },
    },
    footer: {
      menu: {
        advantages: 'Vorteile',
        calculator: 'Rechner',
        faq: 'FAQ',
        subscribe: 'Abonnieren',
      },
      updates: 'Keine Updates verpassen:',
      sub: 'Abonnieren',
    },
    landing: {
      faq: {
        title: 'Häufig gestellte Fragen',
        subtitle:
          'Wenn Sie nicht finden, wonach Sie suchen, senden Sie eine E-Mail an unser Support-Team, und jemand wird sich bei Ihnen melden.',
        list: {
          l1: {
            title: 'Haben Sie ein Partnerprogramm und wie funktioniert es?',
            subtitle:
              'Unser Empfehlungssystem ermöglicht es Ihnen, Freunde einzuladen, unserer Plattform beizutreten und passives Einkommen aus ihren Einnahmen zu erzielen. Wenn ein Freund sich über Ihren Empfehlungslink anmeldet und Gewinne erzielt, erhalten Sie einen Prozentsatz ihrer Einnahmen als Provision. Weitere Informationen folgen in Kürze.',
          },
          l2: {
            title: 'Gibt es Handelsgebühren?',
            subtitle:
              'Wir berechnen keine Gebühren für den Handel auf unserer Plattform. Es gibt jedoch eine geringe Gebühr, ähnlich wie bei Börsen, für jeden von dem Algorithmus durchgeführten Handel.',
          },
          l3: {
            title: 'Gibt es Gebühren für Einzahlungen?',
            subtitle:
              'Nein, es fallen keine zusätzlichen Gebühren für Einzahlungen an. Sie können Geld auf Ihr Konto einzahlen, ohne zusätzliche Gebühren zu zahlen.',
          },
          l4: {
            title: 'Gibt es Gebühren für Abhebungen?',
            subtitle:
              'Wir erheben eine geringe Bearbeitungsgebühr für jede Abhebung, die von der Netzwerklast abhängt. Es gibt jedoch keine zeitlichen Einschränkungen oder Finanzsperren bei Abhebungen, so dass Sie jederzeit Ihr Geld abheben können.',
          },
          l5: {
            title: 'Kann ich meine Einnahmen reinvestieren?',
            subtitle:
              'Ja, Sie können Ihre Einnahmen reinvestieren, um den Zinseszins zu nutzen, oder weiterhin mit Ihrer Basisinvestition verdienen. Unsere Plattform ermöglicht es Ihnen, Ihre Anlagestrategie individuell anzupassen, um Ihre Ziele und Vorlieben bestmöglich zu berücksichtigen.',
          },
          l6: {
            title: 'Muss ich etwas konfigurieren, um die Plattform zu nutzen?',
            subtitle:
              'Nein, Sie müssen nichts konfigurieren. Unser maßgeschneiderter Algorithmus ist so konzipiert, dass er den Fonds eigenständig verwaltet, so dass Sie einfach am KI-gesteuerten Fonds teilnehmen und von unserer Handelsexpertise profitieren können.',
          },
        },
      },
      calculator: {
        title: 'Rentabilitätsrechner',
        subtitle: 'Keine Magie, nur reine Technologie, die von KI und Mathematik unterstützt wird.',
        description:
          'Verwenden Sie unseren Rentabilitätsrechner, um zu bestimmen, wie viel Sie verdienen können, indem Sie einen bestimmten Betrag in unseren KI-gesteuerten Krypto-Handelsalgorithmus investieren. Geben Sie einfach den Betrag ein, den Sie investieren möchten, und wählen Sie den Anlagezeitraum aus, um Ihre potenziellen Einnahmen zu berechnen.',
        note: '*Bitte beachten Sie, dass die Ergebnisse des Rentabilitätsrechners auf realen Handelsdaten des letzten Jahres basieren. Obwohl wir zukünftige Ergebnisse nicht garantieren können, glauben wir, dass unser Algorithmus eine bewährte Erfolgsbilanz hat und Ihnen helfen kann, Ihre Gewinne zu maximieren.',
        duration: 'Dauer:',
        days: 'Tage',
        expected: 'Erwartete Renditen',
        calculate: 'Berechnen',
      },
      roi: {
        title: 'Rentabilitätsdiagramm (ROI)',
        subtitle:
          'Sehen Sie, wie Ihre Einnahmen steigen: Entdecken Sie das Potenzial Ihrer Investitionen auf diesem Diagramm.',
      },
      advantages: {
        title: 'Sicheres und sicheres Trading im Autopilot-Modus',
        subtitle:
          'Cointrapper wurde für Anleger entwickelt, die fortschrittliche Automatisierung und hohe Sicherheitsstandards nutzen möchten, um ihre Investitionen sicher und effizient zu verwalten.',
        list: {
          l1: {
            title: 'Müheloses Trading ohne menschliche Beteiligung',
            subtitle:
              'Unser AI-betriebener Krypto-Handelsalgorithmus automatisiert den Handelsprozess, eliminiert die Notwendigkeit menschlicher Beteiligung und ermöglicht es Ihnen, zurückzulehnen und zuzusehen, wie Ihre Investitionen wachsen.',
          },
          l2: {
            title: 'Bleiben Sie mit der AI-Signalüberwachung auf dem Laufenden',
            subtitle:
              'Unser AI-betriebener Algorithmus überwacht Signale aus der ganzen Welt und liefert Ihnen aktuelle Informationen zu Markttrends, so dass Sie der Konkurrenz immer einen Schritt voraus sind.',
          },
          l3: {
            title: 'Maximieren Sie Ihre Gewinne durch Diversifikation',
            subtitle:
              'Unser Algorithmus handelt mit einer Vielzahl von verschiedenen Coins, was es Ihnen ermöglicht, Ihr Portfolio zu diversifizieren und das Risiko zu minimieren, während Sie gleichzeitig Ihre Gewinne maximieren.',
          },
          l4: {
            title: 'Nachweislich erfolgreiche Ergebnisse aus Live-Trading-Daten',
            subtitle:
              'Alle unsere Algorithmen werden auf echten Live-Daten getestet, um sicherzustellen, dass sie in realen Szenarien effektiv und zuverlässig sind.',
          },
          l5: {
            title: 'Einfache Handhabung, minimale Einrichtung erforderlich',
            subtitle:
              'Unser Algorithmus erfordert eine minimale Beteiligung des Anlegers. Die Einstellungen sind einfach zu konfigurieren und erfordern nur wenig technisches Wissen, so dass er für Anleger aller Erfahrungsstufen zugänglich ist.',
          },
          l6: {
            title: 'Mehrebenenfallen für optimiertes Trading',
            subtitle:
              'Unser einzigartiger Cointrap-Algorithmus richtet Fallen auf mehreren Ebenen ein, um Handelsstrategien zu optimieren, unter Berücksichtigung von Echtzeit-Marktdaten, um Gewinne zu maximieren.',
          },
        },
      },
      fees: 'Melden Sie sich jetzt an, um Zugang zu einzigartigen Vorteilen und Vorteilen zu erhalten',
      join: 'Beitreten',
      hero: {
        launch: 'Baldiger Start! 🚀',
        title: 'Automatisierter <span> KI-gesteuerter</span>{br}Krypto-Handelsalgorithmus',
        subtitle:
          'Revolutionieren Sie den Handel mit fortschrittlicher KI: {br} maximieren Sie die Effizienz und generieren Sie Echtzeitgewinne.',
      },
    },
    errors: {
      modalAuth: {
        title: 'Entschuldigung, im Moment können Sie sich nicht autorisieren.',
        subtitle:
          'Das Projekt befindet sich im Entwicklungsmodus.{br} Bitte abonnieren Sie, um Updates zum Start zu erhalten.',
      },
    },
  },
  'es-ES': {
    header: {
      menu: {
        advantages: 'Ventajas',
        calculator: 'Calculadora',
        faq: 'Preguntas frecuentes',
        subscribe: 'Suscribirse',
      },
      auth: {
        signin: 'acceso',
        signup: 'Registrarse',
      },
    },
    footer: {
      menu: {
        advantages: 'Ventajas',
        calculator: 'Calculadora',
        faq: 'Preguntas frecuentes',
        subscribe: 'Suscribirse',
      },
      updates: 'No te pierdas las actualizaciones:',
      sub: 'Suscribirse',
    },

    landing: {
      faq: {
        title: 'Preguntas frecuentes',
        subtitle:
          'Si no encuentras lo que buscas, envía un correo electrónico a nuestro equipo de soporte y alguien te responderá.',
        list: {
          l1: {
            title: '¿Tienen un programa de afiliados y cómo funciona?',
            subtitle:
              'Nuestro sistema de referidos te permite invitar amigos a unirse a nuestra plataforma y ganar ingresos pasivos a partir de sus ganancias. Cuando un amigo se registra usando tu enlace de referencia y comienza a ganar beneficios, recibirás un porcentaje de sus ganancias como comisión. Más información próximamente.',
          },
          l2: {
            title: '¿Existen tarifas de negociación?',
            subtitle:
              'No cobramos ninguna tarifa por operar en nuestra plataforma. Sin embargo, hay una pequeña tarifa, similar a la de las bolsas, por cada operación realizada por el algoritmo.',
          },
          l3: {
            title: '¿Existen tarifas por depósitos?',
            subtitle:
              'No, no hay cargos adicionales por depósitos. Puedes depositar fondos en tu cuenta sin cargos adicionales.',
          },
          l4: {
            title: '¿Existen tarifas por retiros?',
            subtitle:
              'Cobramos una tarifa de procesamiento baja por cada retiro que depende de la carga de la red. Sin embargo, no hay limitaciones de tiempo ni bloqueos financieros en los retiros, lo que te permite retirar tus fondos en cualquier momento.',
          },
          l5: {
            title: '¿Puedo reinvertir mis ganancias?',
            subtitle:
              'Sí, puedes reinvertir tus ganancias para aprovechar el porcentaje compuesto o seguir ganándolo con tu inversión base. Nuestra plataforma te permite personalizar tu estrategia de inversión para adaptarla mejor a tus objetivos y preferencias.',
          },
          l6: {
            title: '¿Necesito configurar algo para usar la plataforma?',
            subtitle:
              'No, no necesitas configurar nada. Nuestro algoritmo personalizado está diseñado para gestionar el fondo por sí solo, lo que te permite simplemente participar en el fondo gestionado por IA y disfrutar de los beneficios de nuestra experiencia en negociación.',
          },
        },
      },
      calculator: {
        title: 'Calculadora de rentabilidad',
        subtitle: 'Sin magia, solo tecnología pura impulsada por inteligencia artificial y matemáticas.',
        description:
          'Use nuestra calculadora de rentabilidad para determinar cuánto puede ganar invirtiendo una cantidad determinada en nuestro algoritmo de negociación de criptomonedas impulsado por inteligencia artificial. Simplemente ingrese la cantidad que desea invertir y seleccione el período de inversión para calcular sus ganancias potenciales.',
        note: '*Tenga en cuenta que los resultados de la calculadora de rentabilidad se basan en datos de negociación reales del último año. Si bien no podemos garantizar resultados futuros, creemos que nuestro algoritmo tiene un historial comprobado de éxito y puede ayudarlo a maximizar sus ganancias.',
        duration: 'Duración:',
        days: 'días',
        expected: 'Retornos esperados',
        calculate: 'Calcular',
      },
      roi: {
        title: 'Gráfico de rentabilidad (ROI)',
        subtitle: 'Vea cómo aumentan sus ganancias: descubra el potencial de sus inversiones en este gráfico.',
      },
      advantages: {
        title: 'Operaciones seguras y confiables en piloto automático',
        subtitle:
          'Cointrapper fue diseñado para inversores que desean utilizar automatización avanzada y alta seguridad para administrar sus inversiones de manera segura y eficiente.',
        list: {
          l1: {
            title: 'Operaciones sin esfuerzo sin intervención humana',
            subtitle:
              'Nuestro algoritmo de comercio de criptomonedas impulsado por IA automatiza el proceso de negociación, eliminando la necesidad de intervención humana y permitiéndote sentarte y ver cómo crecen tus inversiones.',
          },
          l2: {
            title: 'Mantente adelante con la monitorización de señales de IA',
            subtitle:
              'Nuestro algoritmo impulsado por IA monitorea señales de todo el mundo, brindándote información actualizada sobre las tendencias del mercado y permitiéndote mantenerse adelante de la competencia.',
          },
          l3: {
            title: 'Maximiza las ganancias con la diversificación',
            subtitle:
              'Nuestro algoritmo negocia una variedad de monedas diferentes, lo que te permite diversificar tu cartera y minimizar el riesgo, al tiempo que maximiza las ganancias.',
          },
          l4: {
            title: 'Resultados probados a partir de datos reales de negociación',
            subtitle:
              'Todos nuestros algoritmos se prueban con datos de negociación reales en vivo, lo que garantiza que sean efectivos y confiables en escenarios del mundo real.',
          },
          l5: {
            title: 'Fácil de usar, configuración mínima requerida',
            subtitle:
              'Nuestro algoritmo requiere una participación mínima del inversor. La configuración es fácil de configurar y requiere poco conocimiento técnico, lo que lo hace accesible para inversores de todos los niveles.',
          },
          l6: {
            title: 'Trampas de varios niveles para la negociación optimizada',
            subtitle:
              'Nuestro exclusivo algoritmo Cointrap establece trampas de varios niveles para optimizar las estrategias de negociación, teniendo en cuenta los datos del mercado en tiempo real para maximizar las ganancias.',
          },
        },
      },
      fees: 'Únete ahora para acceder a beneficios y ventajas únicas',
      join: 'Únete a',
      hero: {
        launch: '¡Lanzamiento pronto! 🚀',
        title: 'Algoritmo de trading de criptomonedas <span> automatizado y alimentado por IA</span>',
        subtitle:
          'Revolutioniza el trading con inteligencia artificial avanzada: {br} maximiza la eficiencia y genera ganancias en tiempo real.',
      },
    },

    errors: {
      modalAuth: {
        title: 'Lo sentimos, no puedes autorizar en este momento.',
        subtitle:
          'El proyecto está en modo de desarrollo. {br} Por favor, suscríbete para recibir actualizaciones sobre el lanzamiento.',
      },
    },
  },
}
