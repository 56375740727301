import { DashboardNavbar } from 'src/components/DashboardNavbar'
import { Container } from 'src/core/ds/container'
import styles from './index.module.scss'

export function DashboardLayout({ children }) {
  return (
    <section className={styles.dashboard}>
      <Container>
        <section className={styles.dashboardContainer}>
          <aside className={styles.aside}>
            <DashboardNavbar />
          </aside>
          <div className={styles.main}>{children}</div>
        </section>
      </Container>
    </section>
  )
}
