import { useIntl } from 'react-intl'
import { Icon, IconNames, IconSize } from '../icon'
import { Loader } from '../loader'
import styles from './index.module.scss'
import { ButtonSizes, ButtonType, ButtonVariants } from './types'

type ButtonProps = {
  type: ButtonType
  size?: ButtonSizes
  variant?: ButtonVariants
  onClick?: any
  label?: string
  icon?: IconNames
  disabled?: boolean
  title?: string
  isLoading?: boolean
  isIconOnLeft?: boolean
  iconColor?: string
}

export function Button({
  type = 'button',
  variant = ButtonVariants.primary,
  icon,
  isLoading = false,
  size = ButtonSizes.md,
  onClick,
  disabled = false,
  label,
  title,
  isIconOnLeft = false,
  iconColor = '#fff',
}: ButtonProps) {
  const intl = useIntl()
  let buttonLabel

  if (label) {
    buttonLabel = intl.formatMessage({ id: label })
  }

  return (
    <button
      // eslint-disable-next-line
      type={type}
      title={title}
      onClick={onClick}
      disabled={disabled || isLoading}
      className={`
        ${styles.button}
        ${styles[variant]}
        ${styles[size]}
        ${isIconOnLeft ? styles.leftIcon : ''}
			`}
    >
      <span>{buttonLabel}</span>
      {isLoading ? <Loader /> : <Icon size={IconSize.xs} name={icon} color={iconColor} /> || null}
    </button>
  )
}
